.tmClass {
  margin-top:3.1em;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  align-content: center;
  border: none;
  height: 1200px;
  width:100%
}

.tmFrame {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  align-content: center;
  background-color:#ffffff;
  overflow: hidden;
  position: relative; 
  top: -50px;
  border-width:0;
  height: 100%;
  width:100%
}
